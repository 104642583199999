/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Muli_200ExtraLight = require('./Muli_200ExtraLight.ttf');
export const Muli_300Light = require('./Muli_300Light.ttf');
export const Muli_400Regular = require('./Muli_400Regular.ttf');
export const Muli_500Medium = require('./Muli_500Medium.ttf');
export const Muli_600SemiBold = require('./Muli_600SemiBold.ttf');
export const Muli_700Bold = require('./Muli_700Bold.ttf');
export const Muli_800ExtraBold = require('./Muli_800ExtraBold.ttf');
export const Muli_900Black = require('./Muli_900Black.ttf');
export const Muli_200ExtraLight_Italic = require('./Muli_200ExtraLight_Italic.ttf');
export const Muli_300Light_Italic = require('./Muli_300Light_Italic.ttf');
export const Muli_400Regular_Italic = require('./Muli_400Regular_Italic.ttf');
export const Muli_500Medium_Italic = require('./Muli_500Medium_Italic.ttf');
export const Muli_600SemiBold_Italic = require('./Muli_600SemiBold_Italic.ttf');
export const Muli_700Bold_Italic = require('./Muli_700Bold_Italic.ttf');
export const Muli_800ExtraBold_Italic = require('./Muli_800ExtraBold_Italic.ttf');
export const Muli_900Black_Italic = require('./Muli_900Black_Italic.ttf');
